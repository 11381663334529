import React, { SVGProps } from 'react';

const SVG: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.720397 8.9413c-.480712-.52063-.480712-1.36613 0-1.88676L6.8735.390472c.48072-.520629 1.26139-.520629 1.7421 0 .48071.52063.48071 1.366138 0 1.886768L3.33162 8l5.28014 5.7228c.48071.5206.48071 1.3661 0 1.8867-.48072.5207-1.26139.5207-1.7421 0L.71655 8.94546l.003847-.00416ZM14.2572 15.6054 8.10413 8.9413c-.48072-.52063-.48072-1.36613 0-1.88676L14.2572.390472c.4807-.520629 1.2614-.520629 1.7421 0 .4807.52063.4807 1.366138 0 1.886768L10.7153 8l5.2802 5.7228c.4807.5206.4807 1.3661 0 1.8867-.4807.5207-1.2614.5207-1.7421 0l.0038-.0041Z"
      fill="#1B1B1B"
    />
  </svg>
);
export default SVG;
