import React, { FC, useEffect, useState, useRef } from 'react';

import { getScrollDestination } from '~/components/experts-carousel/ExpertsCarousel.utils';
import { IExpertsCarousel } from '~/interfaces/components/ExpertsCarousel';

import DoubleChevronLeft from '../../assets/icons/double-chevron-left';
import DoubleChevronRight from '../../assets/icons/double-chevron-right';
import throttle from '../utils/throttle';

import ExpertsContent from './inner-components/ExpertContent/ExpertContent';

const ExpertsCarousel: FC<IExpertsCarousel> = ({
  teamDescription,
  experts,
  previousSlideButtonAriaLabel,
  previousSlideButtonTitle,
  nextSlideButtonAriaLabel,
  nextSlideButtonTitle,
}) => {
  const jobTitlesRef = useRef<HTMLParagraphElement[]>([]);
  const [tallestJobTitle, setTallestJobTitle] = useState(0);
  const [leftScroll, setLeftScroll] = useState(0);
  const scrollableContainerRef = useRef<HTMLDivElement | null>(null);
  const firstItemRef = useRef<HTMLDivElement | null>(null);
  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(true);

  useEffect(() => {
    const scrollableContainer = scrollableContainerRef?.current;
    if (scrollableContainer) {
      const { scrollWidth } = scrollableContainer;
      const scrollableWidthInView = scrollableContainer.clientWidth;
      const maxScrollLeft = scrollWidth - scrollableWidthInView;
      setShowNextArrow(leftScroll !== maxScrollLeft);
    }
    setShowPrevArrow(leftScroll !== 0);
  }, [leftScroll]);

  const triggerButton = (forwardDirection: boolean) => {
    const scrollableContainer = scrollableContainerRef.current;
    if (scrollableContainer) {
      const scrollDestination = getScrollDestination(
        scrollableContainerRef,
        firstItemRef,
        forwardDirection,
      );

      const scrollableContainer = scrollableContainerRef?.current;
      if (scrollableContainer) {
        scrollableContainer.scrollTo({ left: scrollDestination, behavior: 'smooth' });

        const { scrollWidth } = scrollableContainer;
        const scrollableWidthInView = scrollableContainer.clientWidth;
        const maxScrollLeft = scrollWidth - scrollableWidthInView;
        setShowNextArrow(scrollDestination !== maxScrollLeft);
      }
      setShowPrevArrow(scrollDestination !== 0);
      setLeftScroll(scrollDestination);
    }
  };

  const onScrollEvent = () => {
    const scrollContainer = scrollableContainerRef.current;
    if (scrollContainer) {
      const currentScrollLeft = scrollContainer.scrollLeft;
      setLeftScroll(currentScrollLeft);
    }
  };

  useEffect(() => {
    const largestJobTitle = jobTitlesRef.current?.reduce((prev, curr) => {
      return curr.offsetHeight > prev ? curr.offsetHeight : prev;
    }, 0);
    setTallestJobTitle(largestJobTitle);
    return () => {
      setTallestJobTitle(0);
    };
  }, []);

  return (
    <div
      data-hydrate
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      className="experts-content"
      onKeyDown={(e) => {
        if (e.key === 'ArrowLeft') {
          triggerButton(false);
        } else if (e.key === 'ArrowRight') {
          triggerButton(true);
        }
      }}
    >
      {teamDescription && <p className="experts-content__team-description">{teamDescription}</p>}
      <div className="experts-content__list-wrap">
        <div
          className="experts-content__list"
          role="list"
          ref={scrollableContainerRef}
          onScroll={throttle(onScrollEvent, 500)}
        >
          {experts.map((expert, i) => (
            <ExpertsContent
              key={`${expert.name}`}
              {...expert}
              jobTitlesRef={jobTitlesRef}
              tallestJobTitle={tallestJobTitle}
              mapIndex={i}
              firstItemRef={firstItemRef}
            />
          ))}
        </div>
        <button
          aria-label={previousSlideButtonAriaLabel}
          onClick={() => triggerButton(false)}
          className={`experts-content__arrow experts-content__prev ${
            showPrevArrow ? '' : 'experts-content__fadeArrow'
          }`}
          title={previousSlideButtonTitle}
        >
          <DoubleChevronLeft />
        </button>
        <button
          aria-label={nextSlideButtonAriaLabel}
          onClick={() => triggerButton(true)}
          className={`experts-content__arrow experts-content__next ${
            showNextArrow ? '' : 'experts-content__fadeArrow'
          }`}
          title={nextSlideButtonTitle}
        >
          <DoubleChevronRight />
        </button>
      </div>
    </div>
  );
};

export default ExpertsCarousel;
