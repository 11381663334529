import React, { SVGProps } from 'react';

const SVG: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.9993 8.9413c.4807-.52063.4807-1.36613 0-1.88676L9.84622.390472c-.48071-.520629-1.26138-.520629-1.7421 0-.48071.52063-.48071 1.366138 0 1.886768L13.3881 8l-5.28013 5.7228c-.48071.5206-.48071 1.3661 0 1.8867.48071.5207 1.26139.5207 1.7421 0l6.15313-6.66404-.0039-.00416ZM2.4625 15.6054l6.1531-6.6641c.48071-.52063.48071-1.36613 0-1.88676L2.4625.390472c-.48072-.520629-1.26139-.520629-1.742103 0-.480712.52063-.480712 1.366138 0 1.886768L6.00438 8 .724243 13.7228c-.480712.5206-.480712 1.3661 0 1.8867.480707.5207 1.261387.5207 1.742097 0l-.00384-.0041Z"
      fill="#1B1B1B"
    />
  </svg>
);
export default SVG;
