import React, { FC } from 'react';

import ImageWrapped from '~/components/image-wrapped/ImageWrapped';
import Link from '~/components/link/Link';
import { IExpert } from '~/interfaces/components/ExpertsCarousel';
import { ILink } from '~/interfaces/components/Link';

interface IExpertExtra extends IExpert {
  jobTitlesRef: React.MutableRefObject<HTMLParagraphElement[]>;
  tallestJobTitle: number;
  mapIndex: number;
  firstItemRef: React.MutableRefObject<HTMLDivElement | null>;
}

const separateJobTitleAndSelfDescriptionFromString: (desc: string) => string[] = (
  wholeDescription: string,
) => {
  const indexOfFullStop = wholeDescription.indexOf('. ');
  if (indexOfFullStop < 0) {
    return [wholeDescription];
  }
  const selfDescription = wholeDescription.substring(indexOfFullStop + 2);
  const jobTitle = wholeDescription.substring(0, indexOfFullStop);
  return [jobTitle, selfDescription];
};

const ExpertContent: FC<IExpertExtra> = ({
  image,
  name,
  description,
  link,
  jobTitlesRef,
  tallestJobTitle,
  mapIndex,
  firstItemRef,
}) => {
  const jobTitleAndSelfDescription = separateJobTitleAndSelfDescriptionFromString(
    description || '',
  );
  const Wrapper = link?.href ? Link : 'div';
  return (
    <div
      role="listitem"
      className="experts-content__item"
      ref={(ref) => {
        if (mapIndex === 0) {
          // eslint-disable-next-line
          firstItemRef.current = ref;
        }
      }}
    >
      <Wrapper {...(link as ILink)}>
        {image && (
          <div className="experts-content__image-container" title={name}>
            <ImageWrapped
              className="experts-content__image"
              {...image}
              noCredit
              width={200}
              height={200}
              removeNativeWidthRestriction
            />
          </div>
        )}
        <div className="experts-content__container">
          <h3 className="experts-content__info experts-content__name">{name}</h3>
          {description && jobTitleAndSelfDescription.length < 2 && (
            <p className="experts-content__info experts-content__description">{description}</p>
          )}
          {description && jobTitleAndSelfDescription.length > 1 && (
            <div>
              <p
                className="experts-content__info experts-content__job-title"
                ref={(ref) => {
                  if (jobTitlesRef.current && ref) {
                    // eslint-disable-next-line
                    jobTitlesRef.current[mapIndex] = ref;
                  }
                }}
                style={tallestJobTitle > 0 ? { height: `${tallestJobTitle}px` } : {}}
              >
                {jobTitleAndSelfDescription[0]}
              </p>
              <div className="experts-content__separator" />
              <p className="experts-content__info experts-content__self-description">
                {jobTitleAndSelfDescription[1]}
              </p>
            </div>
          )}
        </div>
      </Wrapper>
    </div>
  );
};

export default ExpertContent;
