/* tslint:disable */
// eslint-disable-next-line
// @ts-ignore
const throttle = (callback: () => void, limit: number, ...args) => {
  let waiting = false; // Initially, we're not waiting
  const returnFunc = () => {
    // We return a throttled function
    if (!waiting) {
      // If we're not waiting
      callback.apply(returnFunc, args); // Execute users function
      waiting = true; // Prevent future invocations
      setTimeout(() => {
        // After a period of time
        waiting = false; // And allow future invocations
      }, limit);
    }
  };
  return returnFunc;
};

export default throttle;
