export const animateScrollLeft = (
  scrollContainer: HTMLElement,
  distanceToMove: number,
  frames: number,
  currentFrame = 0,
  carryScrollLeft?: number,
) => {
  if (currentFrame === frames) {
    return;
  }
  const currentScrollLeft = carryScrollLeft ?? scrollContainer.scrollLeft;
  const destinationScroll = currentScrollLeft + distanceToMove;
  const increment = distanceToMove / frames;
  const nextScrollLeftValue = currentScrollLeft + increment;
  if (
    (Math.sign(increment) === 1 && nextScrollLeftValue > destinationScroll) ||
    (Math.sign(increment) === -1 && nextScrollLeftValue < destinationScroll)
  ) {
    // eslint-disable-next-line no-param-reassign
    scrollContainer.scrollLeft = destinationScroll;
    return;
  }
  // eslint-disable-next-line no-param-reassign
  scrollContainer.scrollLeft = nextScrollLeftValue;
  window.requestAnimationFrame(() =>
    animateScrollLeft(
      scrollContainer,
      distanceToMove,
      frames,
      currentFrame + 1,
      nextScrollLeftValue,
    ),
  );
};

export const limitToMaxScroll = (maxScroll: number, scrollAmount: number) =>
  scrollAmount > maxScroll ? maxScroll : scrollAmount;

export const getScrollDestination = (
  scrollableContainerRef: React.MutableRefObject<HTMLDivElement | null>,
  firstItemRef: React.MutableRefObject<HTMLDivElement | null>,
  directionForwards: boolean,
) => {
  const container = scrollableContainerRef.current;
  if (!container) {
    return 0;
  }
  // const firstEl = container.firstElementChild as HTMLElement | null;
  const firstEl = firstItemRef.current;
  if (!firstEl) {
    return 0;
  }
  // move unchanging values to 'measurements', changed on resize
  const itemWidth = firstEl.offsetWidth;
  const marginRight = window.getComputedStyle(firstEl).getPropertyValue('margin-right'); // Move to state, changed on resize
  const spaceBetween = parseFloat(marginRight);
  const containerWidth = container.clientWidth;
  const currentScrollLeft = container.scrollLeft;
  const containerScrollLimit = container.scrollWidth;
  const itemAndSpace = itemWidth + spaceBetween;

  if (directionForwards) {
    // Right arrow
    const amountToScrollIfPerfect = containerWidth - (containerWidth % itemAndSpace);
    const amountToGoBackwards = currentScrollLeft % itemAndSpace;
    const scrollValueToMoveForwards =
      currentScrollLeft + (amountToScrollIfPerfect - amountToGoBackwards);
    return limitToMaxScroll(containerScrollLimit, scrollValueToMoveForwards);
  }
  // Left arrow
  const amountOfItemsScrolledPastAtLeastPartially = Math.ceil(currentScrollLeft / itemAndSpace);
  const scrollValueToScrollPastThem =
    itemAndSpace * amountOfItemsScrolledPastAtLeastPartially - spaceBetween;
  const scrollValueToMakeThePartiallySeenOneLineUpOnTheRight =
    scrollValueToScrollPastThem - containerWidth;
  return limitToMaxScroll(
    containerScrollLimit,
    scrollValueToMakeThePartiallySeenOneLineUpOnTheRight,
  );
};
